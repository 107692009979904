import * as React from "react";
import { 
  Grid,
  LinearProgress,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  ButtonGroup,
  Slide,
} from "@mui/material";
import ViewListIcon from '@mui/icons-material/ViewList';
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { TrackingContext } from "../context";
import { useEffect } from "react";
import { FilterBar } from "../components";
import { HasRole, ParseToken, titlePage } from "../utilities";
import CustomPaginationActionsTable from "../components/CustomPaginationActionsTable";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const limit = 100;
const offset = 0;
//19/06/2024 Greg: Lo metto come 'Globale' per il componente in modo da evitare il reset ad ogni rendering
let podDownloadPathVar = ''


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


//02/04/2024 Greg : Il seguente è dismesso, i dati sono mandati da api di config
const listaStato = [
  { value:"CON", text:"Consegnato"},
  { value:"INC", text:" In consegna"},
  { value:"ALTRI", text:"In lavorazione"},
]


const Tracking = () => {
  const { t } = useTranslation("common",{ keyPrefix: 'tracking' })
  const [pageResetVar, setPageResetVar] = useState(false);
  const [confermaDownloadPodOpen, setConfermaDownloadPodOpen ] = useState(false);
  //19/06/2024 Greg: Non utilizzo, troppo lento
  //const [podDownloadPath, setPodDownloadPath ] = useState('');
  const [ pageOffset, setPageOffset ] = useState(0);
  const [NPage, setNPage] = useState(0);


  
  const {
    state: { 
      trackingList,
      columnsCustom,
      recordCount,
      filter,
      resettaFiltri,
      azioni,
      chiediConfermaDownloadPOD,
      titoloConfermaDownloadPOD,
      testoConfermaDownloadPOD,
      filtriStatoTracking,
      creaFileTrackingActive,
      filtriAttiviTracking
    },
    getTrackingList,
    aggiornaFiltri,
    resetFiltri,
    trackEmail,
    getTrackingConfig,
    postCreaFileTracking   

  } = useContext(TrackingContext);

  const navigate = useNavigate();

  useEffect(() => {
    getTrackingConfig();

    titlePage("", t("titlePage"));
    getTrackingList(
      "",
      limit,
      offset,
      navigate,
      [],
      recordCount,
      false,
      filter
    );



  }, []);

  //console.log("Tracking",podDownloadPath)

  const renderDownloadPOD = () => {
    window.open(
      podDownloadPathVar,
      "_blank",
      "noopener,noreferrer"
    )

    if (confermaDownloadPodOpen){
      closePodDownloadDialog()    
    }
    podDownloadPathVar = ''
  }
  
  const downloadPodAction = (params, idElement)  => {
    let path = localStorage.getItem("path");
    let splitPrm = path.split("/");

    //19/06/2024 Greg: Non utilizzo setPodDownloadVar, altrimenti rischio pagine vuota per tempi di esecuzione dell'hook
    //setPodDownloadPath(`${splitPrm[0]}//${splitPrm[2]}/pod/${params[idElement]}`)
    podDownloadPathVar = `${splitPrm[0]}//${splitPrm[2]}/pod/${params[idElement]}`

    if (chiediConfermaDownloadPOD){
      setConfermaDownloadPodOpen(true);
    }
    else {
      renderDownloadPOD()
    };
  }

  

  const closePodDownloadDialog = () => {
    setConfermaDownloadPodOpen(false);
    //setPodDownloadPath('')
  }

  const emailActionTrak=(e)=>{
    trackEmail(e);
   }


   return (
    <div>
      <Grid container spacing={2}>
        <Grid container item mr={2} spacing={2} direction={"row"}>
          <Grid item xs={12} md={12} lg={12}>
            <FilterBar
              type={true}
              recordCount={recordCount}
              filter={filter}
              aggiornaFiltri={aggiornaFiltri}
              getMethod={getTrackingList}
              resetFiltri={resetFiltri}
              setViewStatoWeb={true}
              setViewAdvFilter={true}
              setPageResetVar={setPageResetVar}
              pageResetVar={pageResetVar}
              listStatoWeb={filtriStatoTracking}
              setCounterApi={setPageOffset}
              setPage={setNPage}
              filtriAttivi={filtriAttiviTracking}
              
              //fiiltriMittenteActive={}
              //fiiltriCommittenteActive={}
              //filtriDestinatarioActive={}
              
            ></FilterBar>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>

          {creaFileTrackingActive ? 
            (
            <ButtonGroup size={"large"} orientation={"horizontal"}>

              <Button 
                onClick={postCreaFileTracking} 
                startIcon={<ViewListIcon></ViewListIcon>} 
                variant="contained"
              >
                Esporta file
              </Button>
            </ButtonGroup>
            ):(<></>)
          }

          {columnsCustom.length > 0 ? (
            <CustomPaginationActionsTable
              /* setCheckBoxSelectionPrint={setCheckBoxSelectionPrint} */
              rows={trackingList}
              columns={columnsCustom}
              limit={100}
              navigate={navigate}
              getMethodForList={getTrackingList}
              recordCount={recordCount}
              routeDetail={"/tracking"}
              idElement={"PrgInterno"}
              pageReset={pageResetVar}
              setPageReset={setPageResetVar}
              actionShow={true}
              viewAction={{
                mail: azioni?.email?.visible,
                view: true,
                location: false,
                print: false,
                edit:azioni?.modifica?.visible,
                delete:azioni?.annulla?.visible,
              }}
              emailAction={(e)=>emailActionTrak(e)}
              filterList={filter}
              resettaFiltri={resettaFiltri}
              downloadAction={downloadPodAction}
              counterApi={pageOffset}
              setCounterApi = {setPageOffset}
              page={NPage}
              setPage = {setNPage}

            />
          ) : (
            <Stack justifyContent={"center"} alignContent="center">
              <Typography>{t("loadPage")}</Typography>
              <LinearProgress color="success" />
            </Stack>
          )}
        </Grid>
      </Grid>

      <Dialog
        open={confermaDownloadPodOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setConfermaDownloadPodOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{titoloConfermaDownloadPOD}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {testoConfermaDownloadPOD}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={renderDownloadPOD}>Scarica POD</Button>
          <Button onClick={closePodDownloadDialog}>Annulla</Button>
        </DialogActions>
      </Dialog>
    </div>

  );
};

export default Tracking;
